<template>
    <v-row>
        <v-col cols="12">
            <v-label>Responable encargado/a</v-label>
            <v-autocomplete 
                solo outlined dense flat
                :rules="rules" 
                autocomplete="off" 
                v-model="value[objName]" 
                :items="personas"
                :item-text="(i) => i.first_name + ' ' + i.last_name"
                return-object
                prepend-inner-icon="account_circle"
                @change="update_persona" />
        </v-col>
        <v-col cols="12">
            <v-label>Jefe del Responsable</v-label>
            <PersonaListItem v-model="value.jefe_autor" />
        </v-col>
    </v-row>
</template>

<script>
import PersonaListItem from '@/dash/components/PersonaListItem'

export default {
    props: {
        objName: {
            type: String,
            default: "persona_autor"
        },
        value: {
            type: Object,
            default: null
        },
        personas: {
            type: Array,
            default: () => ([])
        },
        rules: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        PersonaListItem,
    },
    methods: {
        update_persona(item) {
            this.value[this.objName] = { ... item };
            this.value.jefe_autor = item.jefe;
            this.value[this.objName].jefe = !!item.jefe ? item.jefe.id : null;
        }
    }
}
</script>