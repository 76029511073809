<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('edit')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-form id="editor" ref="editor" v-else>
            <v-row>
                <v-col cols="12">
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="4">
                    <DashCard title="General">
                        <v-row dense>
                            <v-col cols="12">
                                <v-label>Fecha de Elaboración</v-label>
                                <DatePickerText dense v-model="permiso.fecha_creacion" :rules="[rules.required]" />
                            </v-col>
                            <v-col cols="12">
                                <v-label>Estado</v-label>
                                <v-select solo outlined 
                                    :rules="[rules.required]" 
                                    v-model="permiso.estado" 
                                    :items="estados" 
                                    :return-obejct="false"
                                    item-text="text" 
                                    item-value="value" 
                                    dense></v-select>
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="8">
                    <DashCard title="Detalles del Permiso">
                        <v-label>Titulo/Descripción de Actividad</v-label>
                        <v-textarea solo outlined dense 
                            :rules="[rules.required]" 
                            v-model="permiso.titulo" 
                            label="Titulo" 
                            counter="250"></v-textarea>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6">
                    <DashCard title="Responsables">
                        <EditorResponsable v-model="permiso" :rules="[rules.required]" :personas="personas" />
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6">
                    <DashCard title="Area y Dueños">
                        <v-row>
                            <v-col cols="12">
                                <v-label>Area</v-label>
                                <Selector dense 
                                        :rules="[rules.required]"
                                        v-model="permiso.area" 
                                        :items="areas" 
                                        return-object
                                        label="Área"
                                        create-dialog-title="Crear nueva Área" 
                                        create-dialog-placeholder="Nombre del Área"
                                        item-text="nombre" />
                            </v-col>
                            <v-col cols="12">
                                <v-label>Dueños</v-label>
                                <GestorPropietarios v-model="permiso.propietarios" :gerencias="gerencias" :rules="[rules.required]" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard title="Tareas" body-class="pa-0" :actions="true" actions-align="right">
                        <GestorTareas edit-mode model-type="permisos" 
                            ref="editor_tarea" 
                            v-model="permiso.tareas" 
                            :personas="personas_simple" />
                        <template slot="actions">
                            <v-btn large depressed color="secondary" @click="add_tarea" form="editor"> 
                                <span class="subtitle-2">Añadir</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard :actions="true" actions-align="right">
                        <template slot="actions">
                            <v-btn type="submit" large depressed color="primary" @click="askSubir" form="editor">
                                <span class="subtitle-2">Guardar cambios</span>
                            </v-btn>
                            <v-btn large text color="primary" @click="askCancelar">
                                <span class="subtitle-2">Cancelar</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
            </v-row>
        </v-form>
        <Message ref="success_dialog" @onClose="onUploadSuccessClose" />
        <Message ref="error_dialog" />
        <CommonDialog ref="cancel_dialog" @onClose="onCancelClose"></CommonDialog>
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import DatePickerText from '@/shared/components/DatePickerText'
import DialogTarea from '@/dash/dialogs/DialogTarea'
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import Selector from '@/dash/components/Selector'
import PersonaListItem from '@/dash/components/PersonaListItem'
import GestorPropietarios from '@/dash/components/GestorPropietarios'
import GestorTareas from '@/dash/components/GestorTareas'
import EditorResponsable from '@/dash/components/EditorResponsable'
import AccessMessage from '@/dash/components/AccessMessage'
import { axiosHelper, messages, valid_required } from '@/helpers'
import raw_estados from '@/assets/estados'

export default {
    components: {
        Message,
        CommonDialog,
        DashCard,
        DatePickerText,
        DialogTarea,
        EditorResponsable,
        GestorPropietarios,
        GestorTareas,
        Selector,
        PersonaListItem,
        AccessMessage
    },
    data: () => ({
        editor_data: {},
        permiso: {
            propietarios: [],
            tareas: []
        },
        rules: {
            required: value => valid_required(value)
        }
    }),
    computed: {
        editMode() {
            return this.$route.meta.editMode;
        },
        areas() {
            return this.editor_data.areas || [];
        },
        gerencias() {
            return this.editor_data.gerencias || [];
        },
        personas_simple() {
            if(!this.editor_data.personas) return [];

            const p = this.editor_data.personas.map(x => ({...x, jefe: (!!x.jefe ? x.jefe.id : null) }));
            return p;
        },
        personas() {
            return this.editor_data.personas || [];
        },
        estados() {
            return raw_estados['permiso'];
        }
    },
    methods: {
        add_tarea() {
            this.$refs.editor_tarea.nueva_tarea();
        },
        askSubir(e) {
            e.preventDefault();

            if(!this.$refs.editor.validate()) 
            {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_check_fields"], "error")
                return;
            }

            if(this.editMode) {
                axiosHelper.put('permisos/' + this.$route.params.id + '/', this.permiso)
                .then(response => this.success())
                .catch(error => this.error());
            } else {
                axiosHelper.post('permisos/', this.permiso)
                .then(response => this.success())
                .catch(error => this.error());
            }
        },
        askCancelar(e) {
            e.preventDefault();
            this.$refs.cancel_dialog.showDialog(
                messages.titles[this.editMode ? "question_cancel_edit" : "question_cancel_new"], 
                messages.texts[this.editMode ? "question_cancel_edit" : "question_cancel_new"], "yes_no");
                
        },
        onCancelClose(e) {
            if(e == "yes") {
                this.$router.back();
            }
        },
        onUploadSuccessClose(e) {
            this.$router.back();
        },
        success() {
            this.$refs.success_dialog.show(
                messages.titles["success_generico"],
                messages.texts[this.editMode ? "success_upload_edit" : "success_upload_new"], 
                "success");
        },
        error() {
            this.$refs.error_dialog.show(
                messages.titles["error_generico"], 
                messages.texts["error_upload"], 
                "error");
        }
    },
    mounted() {
        if(!this.$parent.$parent.get_permission('edit')) return;

        let text = "";

        if(this.editMode) 
        {
            text = "Editar Permiso Crítico";
            axiosHelper.get('permisos/' + this.$route.params.id + '/editar')
                .then(response => {
                    this.editor_data = response.data;
                    this.permiso = this.editor_data.permiso;
                })
        } else {
            text = "Añadir Permiso Crítico";
            axiosHelper.get('permisos/crear')
                .then(response => {
                    this.editor_data = response.data;
                })
        }

        this.$emit("title", { text: text, icon: "edit" });
    }
}
</script>