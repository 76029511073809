<template>
<v-card elevation="2" outlined>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th>Persona</th>
                    <th>Gerencia</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="value.length > 0">
                    <tr v-for="(dato, i) in value" :key="i">
                        <td>{{ dato.persona }}</td>
                        <td>{{ dato.gerencia.nombre }}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td colspan="2">
                            No hay dueños asignados.
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-simple-table>
    <v-divider />
    <v-row class="px-3">
        <v-col cols="12" lg="6">
            <v-text-field solo outlined hide-details flat dense v-model="editor.persona" label="Nombre de persona" />
        </v-col>
        <v-col cols="12" lg="6">
            <v-select 
                solo flat outlined dense
                v-model="editor.gerencia" 
                :items="gerencias" 
                return-object
                label="Gerencia"
                item-text="nombre" 
                hide-details />
        </v-col>
    </v-row>
    <v-card-actions>
        <v-spacer />
        <v-btn depressed color="secondary" large @click="add_persona">
            <v-icon left>add</v-icon>
            <span class="subtitle-2">Agregar</span>
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
export default {
    props: { 
        value: {
            type: Array,
            default: () => ([])
        },
        gerencias: {
            type: Array,
            default: () => ([])
        },
        rules: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        editor: {}
    }),
    methods: {
        add_persona() {
            this.value.push({ ...this.editor });

            this.editor.gerencia = null;
            this.editor.persona = null;
        }
    }
}
</script>