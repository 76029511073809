<template>    
    <v-dialog v-model="visible" max-width="400" persistent transition="fade-transition">
        <WindowCard :title="title" actions-align="right">
            <span class="text--primary">Escriba en el campo de texto</span>
            <v-text-field dense solo outlined v-model="value" :label="label" />
            <template slot="actions">
                <v-btn text large color="primary" @click="closeDialog(false)">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn depressed large color="primary" @click="closeDialog(true)">
                    <span class="subtitle-2">Crear</span>
                </v-btn>
            </template>
        </WindowCard>
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'

export default {
    components: {
        WindowCard
    },
    data: () => ({
        title: "",
        visible: false,
        label: "",
        value: ""
    }),
    methods: {
        closeDialog(accepted) {
            this.visible = false;
            this.$emit('onClose', this.value, accepted);
        },
        show(title, label) {
            this.title = title;
            this.label = label;
            this.visible = true;
        }
    }
}
</script>