<template>
     <div>
        <DialogCreate ref="creator" @onClose="set"></DialogCreate>
            <v-autocomplete solo flat outlined hide-details 
                :rules="rules" 
                :dense="dense" 
                :return-object="returnObject" 
                :items="items" 
                :item-text="itemText" 
                :item-value="itemValue"
                :label="label" 
                :value="value"
                v-on:input="handleValue">
                <template v-slot:append-outer>
                    <v-btn class="ma-n1" icon color="primary" @click="showDialog">
                        <v-icon large>add</v-icon>
                    </v-btn>
                </template>
            </v-autocomplete>
     </div>
</template>

<script>
import DialogCreate from '@/dash/components/DialogCreate'

export default {
    components: {
        DialogCreate
    },
    props: ['value', 'dense', 'items', 'rules', 'item-text', 'item-value', 'return-object', 'label', 'create-dialog-title', 'create-dialog-placeholder'],
    methods: {
        handleValue(value) {
            this.$emit('input', value);
        },
        showDialog() {
            this.$refs.creator.show(this.createDialogTitle, this.createDialogPlaceholder);
        },
        set(item) {
            if(!item) return;

            let newitem = { nombre: item };

            this.items.push(newitem);
            this.handleValue(newitem);
        },
    }
}
</script>